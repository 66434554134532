<template>
  <div class="index">
    <div class="content">
      <div style=" padding: 10px 12px;">
        <van-field
            v-model="message"
            rows="2"
            :autosize="{maxHeight:300,minHeight:150}"
            type="textarea"
            maxlength="2000"
            placeholder="请输入内容"
            show-word-limit
            style="background: #fff ;border: none;padding-bottom: 45px;"
        >
          <template #button>
            <div class="button">
              从素材中心选取
            </div>
          </template>
        </van-field>
        <van-uploader style="width: 100%;">
          <div style="    display: flex;
    align-items: center;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 10px 12px;
    margin-top: -11px;">
            <van-icon name="plus" style="color: #939393" />
            <div style="color: #444;margin-left: 10px;">上传图片/视频</div>
          </div>
        </van-uploader>
        <span style="color: #939393;font-size: 12px;margin-top: 5px;    display: block;"> 发表后，客户可以在我的对外资料中查看朋友圈 </span>
        <van-button disabled color="#294ba3" block style="margin-top: 30px;">发表</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0
    }
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/ .van-empty__image img {
  object-fit: contain;
}

/deep/ .van-field__button {
  position: absolute;
  bottom: -36px;
  font-size: 12px;
  border: 1px solid #294ba3;
  padding: 0;
  color: #294ba3;
  background: #fff;
  padding: 1px 12px;
  border-radius: 18px;
  right: -14px;
}
/deep/ .van-uploader__input-wrapper{
  width: 100%;
}

.van-popover__content {
  .options {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    opacity: 1;
    z-index: 10;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 25%);
    border-radius: 2px;
    padding: 6px 0;

    .clickable {
      padding: 16px 24px 16px 21px;
      word-break: keep-all;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
}

.index {
  font-size: 14px;
  height: 100%;
  background:  #f2f4f8;

  p {
    margin: 0 !important;
  }

  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .content {
    background:#f2f4f8;
    height: 92%;
    //padding-top: 5px;
    img {
      width: 22px;
      height: 22px;
    }

    .title {
      padding: 12px 16px;
      justify-content: space-between;
      font-weight: 400;
      line-height: 18px;
      font-size: 12px;
      color: #545454;

      .circle-z {
        font-weight: 500;
        margin-right: 5px;
        color: #000;
      }
    }

    .content-body {
      border-top: 8px solid #f4f5f7;
    }

    .item-list {
      .item {
        padding: 12px 16px;
        /* border: 1px solid red; */
        border-bottom: 1px solid #f4f5f7;

        div:nth-child(1) {
          display: flex;

          img {
            width: 55px;
            height: 55px;
          }

          span {
            color: #444;
            font-size: 15px;
            margin-left: 10px;
          }
        }

        div:nth-child(2) {
          color: #939393;
          margin-top: 10px;
          font-size: 12px;
        }
      }
    }

  }
}
</style>
